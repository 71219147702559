import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Table,
  notification,
  DatePicker,
  Descriptions,
  Checkbox,
  Space,
  Tooltip,
  Popconfirm,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faUserEdit,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import ReadyForDeliveryOrder from "../../../services/storereadyfordeliverysplitorderservice";
import Moment from "moment";
import { Link, withRouter } from "react-router-dom";
import ROUTES from "../../../routes";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";

const { Search } = Input;
const { Option } = Select;
const TWO_MONTHS_AGO = new Date();
TWO_MONTHS_AGO.setMonth(TWO_MONTHS_AGO.getMonth() - 3); //commented by Rashmi 03/03/23

class Master extends React.Component {
  constructor(props) {
    super(props);
    this.ReadyForDeliveryOrderService = new ReadyForDeliveryOrder();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      deliveryOrderItems: [],
      ID: 0,
      data: [],
      selectedRowKeys: [],
      showfilterBox: true,
      loading: false,
      fromDate: Moment(TWO_MONTHS_AGO),
      // fromDate: Moment(TWO_MONTHS_AGO).startOf(1, "months").date(1),
      toDate: Moment(),
      invoiceDate: null,
      performaDate: null,
      customerName: {
        //  disabled: true,
        data: [],
        selected: null,
      },
      selectedOrdersOfCustomer: null,
      search: null,
      sorter: {
        field: null,
        order: null,
      },
      selectedBranch: localStorage.getItem("branchID"),
      selectedOrderID: null,
      invoiceStatusFilter: {
        data: [],
        selected: "1,2",
      },
      OrderTypeFilter: {
        data: [],
        selected: "",
      },
      pagination: {
        showSizeChanger: true,
        // pageSizeOptions: [1, 2, 3, 4],
        current: 1,
        pageSize: 10,
        onChange: (page, pageSize) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: page,
              pageSize: pageSize,
            },
          });
        },
        onShowSizeChange: (current, size) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: current,
              pageSize: size,
            },
          });
        },
      },
      IsCheckNow: 0,
      OrderStatusFilter: {
        data: [],
        selected: "",
      },
    };
  }

  columns = [
    // {
    //   title: "ID",
    //   dataIndex: "ID",
    //   key: "ID",
    // },
    {
      title: labels.SPLIT_ORDER,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{record.splitOrderNumber}</div>
            <small>{record.orderTypeName}</small>
          </React.Fragment>
        );
      },
    },
    {
      title: labels.ORDER_COUNT,
      dataIndex: "orderCount",
      key: "orderCount",
      sorter: true,
    },
    {
      title: labels.BAG_COUNT,
      dataIndex: "bagCount",
      key: "bagCount",
      sorter: true,
    },
    {
      title: labels.CUSTOMER,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{record.customerName}</div>
            <small>{record.customerTypeName}</small>
          </React.Fragment>
        );
      },
    },
    {
      title: labels.CUST_MO_No,
      dataIndex: "mobileNo",
      key: "mobileNo",
      sorter: true,
    },
    {
      title: labels.PLATFORM,
      dataIndex: "platform",
      key: "platform",
      sorter: true,
    },

    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{record.storeName}</div>
          </React.Fragment>
        );
      },
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryTypeName",
      key: "deliveryTypeName",
      sorter: true,
    },
    {
      title: labels.DELIVERY_DATE,
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      sorter: true,
      render: (text) =>
        text !== null ? Moment(text).format("DD-MM-YYYY") : null,
    },
    // {
    //   title: labels.FACTORYOUTDATE,
    //   dataIndex: "factoryOutDateTime",
    //   key: "factoryOutDateTime",
    //   sorter: true,
    //   render: (text) =>
    //     text !== null ? Moment(text).format("DD-MM-YYYY") : null,
    // },

    // {
    //   title: labels.STOREINDATE,
    //   dataIndex: "storeInDatetime",
    //   key: "storeInDatetime",
    //   sorter: true,
    //   render: (text) => {
    //     return Moment(text).format("YYYY-MM-DD") === "0001-01-01"
    //       ? null
    //       : Moment(text).format("DD-MM-YYYY");
    //   },
    // },
    {
      title: labels.ORDER_STATUS,
      dataIndex: "orderStatusName",
      key: "orderStatusName",
      sorter: true,
    },
    {
      title: labels.INVOICE_STATUS,
      dataIndex: "invoiceStatusName",
      key: "invoiceStatusName",
      sorter: true,
    },
    {
      title: labels.NO_OF_GARMENTS,
      dataIndex: "noOfGarment",
      key: "noOfGarment",
      sorter: true,
    },

    {
      title: labels.INVOICE_NUMBER,
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      sorter: true,
    },
    {
      title: labels.AMOUNT,
      dataIndex: "finalInvoiceAmount",
      key: "finalInvoiceAmount",
      sorter: true,
    },

    // {
    //   title: "Order Type",
    //   dataIndex: "orderTypeName",
    //   key: "orderTypeName",
    //   sorter: true,
    // },
    {
      title: labels.TYPE,
      dataIndex: "onDemandWalkin",
      key: "onDemandWalkin",
      sorter: true,
    },
    {
      title: labels.SERVICE,
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: true,
    },

    // {
    //   title: "Service Amount",
    //   dataIndex: "serviceAmount",
    //   key: "serviceAmount",
    //   //   sorter: true,
    // },
    // {
    //   title: "Add On Service Amount",
    //   dataIndex: "addOnServiceAmount",
    //   key: "addOnServiceAmount",
    //   //   sorter: true,
    // },
    // {
    //   title: "Discount Amount",
    //   dataIndex: "discountAmount",
    //   key: "discountAmount",
    //   //   sorter: true,
    // },
    // {
    //   title: "Delivery Charges",
    //   dataIndex: "deliveryChargesAmount",
    //   key: "deliveryChargesAmount",
    //   //   sorter: true,
    // },
    // {
    //   title: "Packaging Charges",
    //   dataIndex: "packagingCharges",
    //   key: "packagingCharges",
    //   //   sorter: true,
    // },

    // {
    //   title: "Invoice Date",
    //   dataIndex: "invoiceDate",
    //   key: "invoiceDate",
    //   //   sorter: true,
    //   render: (text) =>
    //     text !== null ? Moment(text).format("DD-MM-YYYY") : null,
    // },

    // {
    //   title: "Performa Number",
    //   dataIndex: "performaNumber",
    //   key: "performaNumber",
    //   //   sorter: true,
    // },
    // {
    //   title: "Performa Invoice Date",
    //   dataIndex: "perfomaInvoiceDate",
    //   key: "perfomaInvoiceDate",
    //   render: (text) =>
    //     text !== null ? Moment(text).format("DD-MM-YYYY") : null,
    //   //   sorter: true,
    // },

    {
      title: labels.ACTIONS,
      key: "raise",
      render: (text, record) => (
        <React.Fragment>
          <Space>
            <Link
              to={{
                pathname: [
                  ROUTES.PROFORMA_INVOICE,
                  record.splitOrderNumber,
                ].join("/"),
                state: { splitOrderNumber: record.splitOrderNumber },
              }}
            >
              {record.performaNumber === null
                ? labels.GENERATE
                : record.invoiceStatus === 1
                ? labels.SETTLE
                : labels.VIEW}
            </Link>
            {record.orderStatus === 15 ? (
              <Tooltip title="Mark Order As Completed">
                <Popconfirm
                  title={
                    labels.ARE_YOU_SURE_YOU_WANT_TO_MARK_ORDER_AS_COMPLETED
                  }
                  onConfirm={() =>
                    this.SplitOrderMarkAsDelivered(record.splitOrderNumber)
                  }
                  onCancel={() => null}
                  okText={labels.YES}
                  cancelText={labels.NO}
                >
                  <Button type="link">
                    <FontAwesomeIcon icon={faClipboardCheck} />
                  </Button>
                </Popconfirm>
              </Tooltip>
            ) : null}
          </Space>
        </React.Fragment>
      ),
    },

    {
      title: "Status",
      dataIndex: "phonePeStatus",
      key: "phonePeStatus",   
      // dataIndex: "qrCodeStatus",
      // key: "qrCodeStatus",     
         //sorter: true,
    },
    // {
    //   title: labels.CASH,
    //   dataIndex: "cash",
    //   key: "cash",
    //   sorter: true,
    // },
    // {
    //   title: labels.LKBONUS,
    //   dataIndex: "lkBonus",
    //   key: "lkBonus",
    //   sorter: true,
    // },
    // {
    //   title: labels.LKWALLET,
    //   dataIndex: "lkWallet",
    //   key: "lkWallet",
    //   sorter: true,
    // },
    // {
    //   title: labels.PAYTM,
    //   dataIndex: "paytm",
    //   key: "paytm",
    //   sorter: true,
    // },
  ];

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.READY_FOR_DELIVERY_ORDERS
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    if (![null, undefined, ""].includes(this.props.location.state)) {
      if (![null, undefined, ""].includes(this.props.location.state.search)) {
        this.setState({ search: this.props.location.state.search }, () => {
          this.fetchItems(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sorter.field,
            this.state.sorter.order,
            this.state.search
          );
        });
      } else {
        this.fetchItems(
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search
        );
      }
    } else {
      this.fetchItems(
        this.state.pagination.current,
        this.state.pagination.pageSize,
        this.state.sorter.field,
        this.state.sorter.order,
        this.state.search
      );
    }
    this.getInvoiceStatusList();
    this.getOrderTypeList();
    this.getOrderStatusList();
  }

  SplitOrderMarkAsDelivered = (splitOrderNumber) => {
    this.ReadyForDeliveryOrderService.SplitOrderMarkAsCompleted(
      splitOrderNumber
    ).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.READY_FOR_DELIVERY_OR_INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.ORDER_MARKED_AS_COMPLETED,
          duration: 1.0,
          onClose: () => window.location.reload(),
        });
      } else {
        notification["error"]({
          message: labels.READY_FOR_DELIVERY_OR_INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_MARK_ORDER_AS_COMPLETED,
        });
      }
    });
  };

  getOrderStatusList = () => {
     //if passed 2 to get SplitOrderStatus
    this.ReadyForDeliveryOrderService.getInvoiceStatusList(2).then(
      (response) => {
        if (response.statusCode === 200) {
          const data = response.data.map((single) => ({
            value: single.enumDetailID,
            label: single.enumDetailName,
          }));

          const filteredData = data.filter((item) => [9, 10, 11, 12, 14, 15].includes(item.value));

          this.setState({
            OrderStatusFilter: {
              ...this.state.OrderStatusFilter,
              data: filteredData,
            },
          });
        }
      } 
    );

  };

  getInvoiceStatusList = () => {
    //if passed 1 to get invoicestatus
    // if passed 0 then will get order type i.e ondemand/walk-in
    this.ReadyForDeliveryOrderService.getInvoiceStatusList(1).then(
      (response) => {
        if (response.statusCode === 200) {
          let data = response.data.map((single) => {
            return {
              value: single.enumDetailID,
              label: single.enumDetailName,
            };
          });
          this.setState({
            invoiceStatusFilter: {
              ...this.state.invoiceStatusFilter,
              data: data,
            },
          });
        }
      }
    );
  };

  getOrderTypeList = () => {
    //if passed 1 to get invoicestatus
    // if passed 0 then will get order type i.e ondemand/walk-in
    this.ReadyForDeliveryOrderService.getInvoiceStatusList(0).then(
      (response) => {
        if (response.statusCode === 200) {
          let data = response.data.map((single) => {
            return {
              value: single.enumDetailID,
              label: single.enumDetailName,
            };
          });
          this.setState({
            OrderTypeFilter: {
              ...this.state.OrderTypeFilter,
              data: data,
            },
          });
        }
      }
    );
  };

  searchCustomer = (value) => {
    this.ReadyForDeliveryOrderService.searchCustomer(value).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          customerName: {
            ...this.state.customerName,
            data: response.data,
          },
        });
      } else {
        notification["error"]({
          message: labels.READY_FOR_DELIVERY_OR_INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_CUSTOMERS,
        });
      }
    });
  };
  //function for get data from server call
  //   fetchItems = (params = {}) => {
  fetchItems = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    this.setState({ loading: true });
    // const params = {
    //   storeIDs: localStorage.getItem("branchID"),
    //   customerID: this.state.customerName.selected,
    //   splitOrderNumber: [null, undefined, ""].includes(
    //     document.getElementById("SplitOrderNo")
    //   )
    //     ? null
    //     : document.getElementById("SplitOrderNo").value,
    //   fromOrderDate: Moment(this.state.fromDate).format("YYYY-MM-DD"),
    //   toOrderDate: Moment(this.state.toDate).format("YYYY-MM-DD"),
    //   invoiceNumber: null, // document.getElementById("invoiceNo").value,
    //   invoiceDate: null, // this.state.invoiceDate !== null
    //   //   ? Moment(this.state.invoiceDate).format("YYYY-MM-DD")
    //   //   : null,
    //   performaNumber: null, //document.getElementById("performaNo").value,
    //   perfomaInvoiceDate: null,
    //   // this.state.performaDate !== null
    //   //   ? Moment(this.state.performaDate).format("YYYY-MM-DD")
    //   //   : null,
    // };

    this.ReadyForDeliveryOrderService.retrieveItems(
      index,
      size,
      sortColumn,
      sortOrder,
      search,
      [null, undefined, ""].includes(this.state.selectedBranch)
        ? localStorage.getItem("branchID")
        : this.state.selectedBranch,
      // localStorage.getItem("branchID").toString(),
      this.state.customerName.selected,
      [null, undefined, ""].includes(document.getElementById("SplitOrderNo"))
        ? null
        : document.getElementById("SplitOrderNo").value,
      Moment(this.state.fromDate).format("YYYY-MM-DD"),
      Moment(this.state.toDate).format("YYYY-MM-DD"),
      null,
      null,
      null,
      null,
      this.state.invoiceStatusFilter.selected,
      this.state.OrderTypeFilter.selected,
      this.state.IsCheckNow,
      this.state.OrderStatusFilter.selected
    ).then((items) => {
      if (items.statusCode === 200) {
        if (items.data.length === 0 && this.state.pagination.current !== 1) {
          this.setState(
            { pagination: { ...this.state.pagination, current: 1 } },
            () => {
              this.fetchItems(1, size, sortColumn, sortOrder, search);
            }
          );
        }
        this.setState({
          deliveryOrderItems: items.data.map((single, index) => {
            single.key = single.splitOrderNumber;
            single.customerId = single.customerID;
            return single;
          }),
        });
        this.setState({
          pagination: {
            ...this.state.pagination,
            total: this.state.deliveryOrderItems.length
              ? this.state.deliveryOrderItems[0].totalRecords
              : 0,
            current: this.state.deliveryOrderItems.length
              ? this.state.pagination.current
              : 1,
          },
        });
      } else {
        notification["error"]({
          message: labels.READY_FOR_DELIVERY_OR_INVOICE,
          description:
            labels.UNABLE_TO_GET_LIST_OF_READY_FOR_DELIVERY_INVOICE_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleChangeBranch = () => {
    const { pagination } = this.state;
    this.fetchItems(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  };

  render() {
    const { pagination, loading } = this.state;
    const deliveryOrderItems = this.state.deliveryOrderItems;

    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();

    return (
      <div>
        <PageHeader
          title={labels.READY_FOR_DELIVERY_ORDERS}
          extra={[
            <div key="5" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={this.state.IsCheckNow}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.setState(
                      {
                        IsCheckNow: 1,
                      },
                      () => {
                        this.fetchItems(
                          this.state.pagination.current,
                          this.state.pagination.pageSize,
                          this.state.sorter.field,
                          this.state.sorter.order,
                          this.state.search
                        );
                      }
                    );
                  } else {
                    this.setState(
                      {
                        IsCheckNow: 0,
                      },
                      () => {
                        this.fetchItems(
                          this.state.pagination.current,
                          this.state.pagination.pageSize,
                          this.state.sorter.field,
                          this.state.sorter.order,
                          this.state.search
                        );
                      }
                    );
                  }
                }}
              >
                Check Now
              </Checkbox>
            </div>,
            <Select
              showSearch
              mode="multiple"
              allowClear
              // onChange={this.handleSelect}
              onChange={(event) => {
                this.setState(
                  {
                    selectedBranch: [null, undefined, ""].includes(event)
                      ? ""
                      : event.join(","),
                  },
                  () => {
                    this.handleChangeBranch();
                  }
                );
              }}
              value={
                [null, undefined, ""].includes(this.state.selectedBranch)
                  ? [localStorage.getItem("branchID")]
                  : this.state.selectedBranch.split(",")
              }
              placeholder={labels.SELECT_STORE}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ minWidth: 150, maxWidth: 300 }}
            >
              {storeBranchTypes.length !== 0
                ? storeBranchTypes.map((branchType) => {
                    return branchType.branchID !== "-1" ? (
                      <Option
                        value={branchType.branchID.toString()}
                        key={branchType.branchID.toString()}
                      >
                        {branchType.branchName}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedBranch) &&
                  this.state.selectedBranch.split(",").length ===
                    allStoreBranchArray.length &&
                  this.state.selectedBranch.split(",").slice().sort()
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = storeBranchTypes
                      .map((branchType) => {
                        return branchType.branchID;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedBranch: allOpt.join(","),
                        // storeKeys: [],
                        // factoryKeys: [],
                        // // tagNos:""
                      },
                      () => {
                        if (this.state.selectedBranch !== "") {
                          this.handleChangeBranch();
                        }
                        // else {
                        //   this.setState({
                        //     storeOutTags: [],
                        //     btnMissedTagEnable: true,
                        //   });
                        // }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedBranch: localStorage.getItem("branchID"),
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Search
                placeholder={labels.SEARCH}
                className={styles.SearchInput}
                key="1"
                style={{ minWidth: 150 }}
                value={this.state.search}
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.fetchItems(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />
            </div>,
            <div key="4" style={{ alignSelf: "center" }}>
              <Button
                key="1"
                disabled={this.state.selectedRowKeys.length === 0}
                onClick={() => {
                  this.props.history.push({
                    pathname: [
                      ROUTES.PROFORMA_INVOICE,
                      this.state.selectedRows.length > 1
                        ? this.state.selectedRows
                            .map((x) => x.invoiceID)
                            .join(",")
                        : this.state.selectedRows[0].splitOrderNumber,
                    ].join("/"),
                    state: {
                      splitOrderNumber:
                        this.state.selectedRows.length > 1
                          ? this.state.selectedRows
                              .map((x) => x.invoiceID)
                              .join(",")
                          : this.state.selectedRows[0].splitOrderNumber,
                      splitId: this.state.selectedRows[0].splitOrderNumber,
                    },
                    customerID: this.state.selectedOrdersOfCustomer,
                  });
                  // this.props.history.push(
                  //   [
                  //     ROUTES.PROFORMA_INVOICE,
                  //     this.state.selectedRows.length > 1
                  //       ? this.state.selectedRows
                  //           .map((x) => x.invoiceID)
                  //           .join(",")
                  //       : this.state.selectedRows[0].splitOrderNumber,
                  //   ].join("/"),
                  //   { customerID: this.state.selectedOrdersOfCustomer }
                  // )
                }}
              >
                {labels.SETTLE}
              </Button>
            </div>,
            <div key="4" style={{ alignSelf: "center" }}>
              <Button
                key="2"
                type="link"
                className={styles.FilterToggle}
                onClick={() => {
                  this.setState({ showfilterBox: !this.state.showfilterBox });
                }}
              >
                {this.state.showfilterBox
                  ? labels.HIDE_FILTERS
                  : labels.SHOW_FILTERS}
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className={
                    this.state.showfilterBox
                      ? styles.FilterToggleIcon
                      : styles.FilterToggleIconRotated
                  }
                />
              </Button>
            </div>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.fetchItems(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              )
            }
            initialValues={{
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"fromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "fromDate")}
                    value={this.state.fromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"toDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "toDate")}
                    value={this.state.toDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item>
                  <Input
                    allowClear
                    placeholder={labels.ENTER_SPLIT_ORDER_NUMBER}
                    id="SplitOrderNo"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item name="customerName">
                  <Select
                    onSearch={this.searchCustomer}
                    placeholder={labels.SEARCH_CUSTOMER}
                    showSearch
                    allowClear
                    onChange={(value) => {
                      this.setState({
                        customerName: {
                          ...this.state.customerName,
                          selected: value,
                        },
                      });
                    }}
                    filterOption={false}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    //   0
                    // }
                  >
                    {this.state.customerName.data.length !== 0
                      ? this.state.customerName.data.map(
                          (singleCustomerName) => {
                            return singleCustomerName.customerID !== "-1" ? (
                              <Option
                                value={singleCustomerName.customerID}
                                key={singleCustomerName.customerID}
                              >
                                {singleCustomerName.customerName +
                                  " (" +
                                  singleCustomerName.mobileNo +
                                  ")"}
                              </Option>
                            ) : null;
                          }
                        )
                      : null}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={6}>
                <Form.Item name="invoiceStatus">
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder={labels.INVOICE_STATUS}
                    className={styles.test1}
                    style={{ minWidth: 150, maxWidth: 250 }}
                    // defaultValue={["Performa Generated","Partial Payment received"]}
                    defaultValue={
                      [null, undefined, "", []].includes(
                        this.state.invoiceStatusFilter.selected
                      )
                        ? undefined
                        : this.state.invoiceStatusFilter.selected.split(",")
                    }
                    // value={
                    //   [null, undefined, "", []].includes(
                    //     this.state.invoiceStatusFilter.selected
                    //   )
                    //     ? undefined
                    //     : this.state.invoiceStatusFilter.selected.split(",")
                    // }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          invoiceStatusFilter: {
                            ...this.state.invoiceStatusFilter,
                            selected: [null, undefined, ""].includes(value)
                              ? undefined
                              : value.join(","),
                          },
                        },
                        () => {
                          // console.log(this.state.invoiceStatusFilter.selected);
                        }
                      )
                    }
                  >
                    {this.state.invoiceStatusFilter.data.map((x) => {
                      return x.value != "-1" ? (
                        <option
                          key={x.value.toString()}
                          value={x.value.toString()}
                        >
                          {x.label}
                        </option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item name="OrderType">
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder={labels.ORDER_TYPE}
                    className={styles.test1}
                    style={{ minWidth: 150, maxWidth: 250 }}
                    // value={
                    //   [null, undefined, "", []].includes(
                    //     this.state.OrderTypeFilter.selected
                    //   )
                    //     ? undefined
                    //     : this.state.OrderTypeFilter.selected.split(",")
                    // }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          OrderTypeFilter: {
                            ...this.state.OrderTypeFilter,
                            selected: [null, undefined, ""].includes(value)
                              ? undefined
                              : value.join(","),
                          },
                        },
                        () => {
                          // console.log(this.state.OrderTypeFilter.selected);
                        }
                      )
                    }
                  >
                    {this.state.OrderTypeFilter.data.map((x) => {
                      return x.value != "-1" ? (
                        <option
                          key={x.value.toString()}
                          value={x.value.toString()}
                        >
                          {x.label}
                        </option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item name="Order Status">
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder={labels.ORDER_STATUS}
                    className={styles.test1}
                    style={{ minWidth: 150, maxWidth: 250 }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          OrderStatusFilter: {
                            ...this.state.OrderStatusFilter,
                            selected: [null, undefined, ""].includes(value)
                              ? undefined
                              : value.join(","),
                          },
                        },
                        () => {
                          // console.log(this.state.OrderTypeFilter.selected);
                        }
                      )
                    }
                  >
                    {this.state.OrderStatusFilter.data.map((x) => {
                      return x.value != "-1" ? (
                        <option
                          key={x.value.toString()}
                          value={x.value.toString()}
                        >
                          {x.label}
                        </option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter invoice Number" id="invoiceNo" />
                </Form.Item>
              </Col>
              <Form.Item name={"invoiceDate"}>
                <DatePicker
                  placeholder="Invoice Date"
                  format={"DD-MM-YYYY"}
                  onChange={(date) =>
                    this.handleDateChange(date, "invoiceDate")
                  }
                />
              </Form.Item>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter Performa Number" id="performaNo" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name={"performaDate"}>
                  <DatePicker
                    placeholder="PerformaInvoiceDate"
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "performaDate")
                    }
                  />
                </Form.Item>
              </Col> */}
              {/* <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              {/* <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col> */}
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.deliveryOrderItems}
          pagination={this.state.pagination}
          loading={loading}
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedOrderID:
                  selectedRows.length > 0
                    ? selectedRows[0].orderID.toString()
                    : null,
                // selectedOrdersOfCustomer:
                //   selectedRows.length > 0 ? selectedRows[0].customerId : null,
                // selectedBranch:
                //   selectedRows.length > 0
                //     ? selectedRows[0].storeID.toString()
                //     : null,
              });
              this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows,
              });
            },
            getCheckboxProps: (record) => {
              let shouldDisable = false;
              if (record.performaNumber === null || record.invoiceStatus > 1) {
                shouldDisable = true;
              } else {
                // if (this.state.selectedOrdersOfCustomer === null) {
                if ([null].includes(this.state.selectedOrderID)) {
                  shouldDisable = false;
                } else if (
                  this.state.selectedOrderID === record.orderID.toString()
                  // this.state.selectedOrderID === record.customerId &&
                  // this.state.selectedBranch === record.storeID.toString()
                ) {
                  shouldDisable = false;
                } else {
                  shouldDisable = true;
                }
              }
              return { disabled: shouldDisable };
            },
          }}
          // expandable={{
          //   expandedRowRender: (record) => {
          //     return (
          //       <Descriptions>
          //         {record.deliveryDate !== "" &&
          //         record.deliveryDate !== null ? (
          //           <Descriptions.Item label={labels.DELIVERY_DATE}>
          //             {Moment(record.deliveryDate).format("YYYY-MM-DD")}
          //           </Descriptions.Item>
          //         ) : null}
          //         <Descriptions.Item label={labels.DELIVERY_TYPE}>
          //           {record.deliveryTypeName}
          //         </Descriptions.Item>
          //         <Descriptions.Item label={labels.PROFORMA_INVOICE_NUMBER}>
          //           {record.performaNumber}
          //         </Descriptions.Item>
          //         {[null, undefined, ""].includes(
          //           record.perfomaInvoiceDate
          //         ) ? null : (
          //           <Descriptions.Item label={labels.PROFORMA_INVOICE_DATE}>
          //             {Moment(record.perfomaInvoiceDate).format("YYYY-MM-DD")}
          //           </Descriptions.Item>
          //         )}
          //         {[null, undefined, ""].includes(record.invoiceDate) ? null : (
          //           <Descriptions.Item label={labels.INVOICE_DATE}>
          //             {Moment(record.invoiceDate).format("YYYY-MM-DD")}
          //           </Descriptions.Item>
          //         )}
          //         <Descriptions.Item label={labels.NO_OF_GARMENTS}>
          //           {record.noOfGarment}
          //         </Descriptions.Item>
          //       </Descriptions>
          //     );
          //   },
          // }}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.fetchItems(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(Master);
